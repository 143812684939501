import { tns } from "tiny-slider/src/tiny-slider";
import "tiny-slider/dist/tiny-slider.css";

function updatePositionDisplay({ displayIndex, slideCount }, container) {
  document.querySelector(container).innerHTML = `${displayIndex} of ${slideCount}`;
}

export const slider = (configs) =>
  configs.map((config) => {
    const $slider = tns({
      ...config,
    });

    updatePositionDisplay($slider.getInfo(), config.countContainer);

    $slider.events.on("indexChanged", (info) => updatePositionDisplay(info, config.countContainer));

    return {
      config,
      $slider,
    };
  });
