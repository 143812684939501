import breakpointState from "@/helpers/breakpoint-helper";

export const breakpointMixin = {
  data() {
    return {
      breakpoints: breakpointState(),
    };
  },
  computed: {
    isMobile() {
      return !this.breakpoints.large;
    },
  },
};
