<template>
  <header role="banner">
    <div class="m-header">
      <div class="wrapper">
        <div class="g align-v-center">
          <div class="g__col">
            <masthead-logo :logo="logo" />
          </div>
          <div class="g__col g__col-auto items-end align-v-center">
            <search-box-desktop v-if="isSearchExpanded" @close="toggleSearch" />
            <desktop-menu-top-level
              v-else
              :items="items"
              :expanded-item="expandedItem"
              @toggleitem="toggleItem"
              @togglesearch="toggleSearch"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Child nav here -->
    <desktop-menu-second-level
      v-if="expandedItem && expandedItem.children"
      :parent="expandedItem"
      :items="expandedItem.children"
    />
  </header>
</template>
<script>
import DesktopMenuSecondLevel from "@/components/molecules/menu/DesktopMenuSecondLevel";
import DesktopMenuTopLevel from "@/components/molecules/menu/DesktopMenuTopLevel";
import MastheadLogo from "@/components/molecules/masthead/MastheadLogo";
import SearchBoxDesktop from "@/components/molecules/search-box/SearchBoxDesktop";

export default {
  components: {
    DesktopMenuTopLevel,
    DesktopMenuSecondLevel,
    MastheadLogo,
    SearchBoxDesktop,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    logo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expandedItem: null,
      isSearchExpanded: false,
    };
  },
  methods: {
    toggleItem({ item }) {
      return this.isExpanded(item) ? this.contract(item) : this.expandItem(item);
    },
    toggleSearch() {
      this.expandedItem = null;
      this.isSearchExpanded = !this.isSearchExpanded;
    },
    expandItem(item) {
      this.isSearchExpanded = false;
      this.expandedItem = item;
    },
    expanderTitle(item) {
      return this.isExpanded(item) ? "Expand section navigation" : "Hide section navigation";
    },
    contract() {
      this.expandedItem = null;
    },
    isExpanded(item) {
      return item === this.expandedItem;
    },
  },
};
</script>
