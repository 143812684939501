<template>
  <header
    class="m-header__wrapper"
    role="banner"
    :class="{ 'm-header__wrapper--expanded': isMenuExpanded }"
  >
    <div class="m-header m-header--mini">
      <div class="wrapper">
        <div class="g align-between">
          <div class="g__col g__col-auto">
            <masthead-logo :logo="logo" />
          </div>
          <search-trigger :is-expanded="isSearchExpanded" @toggle="toggleSearch" />
          <mobile-menu-trigger :is-expanded="isMenuExpanded" @toggle="toggleMenu" />
        </div>
      </div>
    </div>
    <mobile-menu-content
      v-show="isMenuExpanded"
      :items="items"
      :aria-expanded="isMenuExpanded ? 'true' : 'false'"
    />
    <search-box-mobile v-show="isSearchExpanded" @close="toggleSearch" />
  </header>
</template>
<script>
import MastheadLogo from "@/components/molecules/masthead/MastheadLogo";
import MobileMenuContent from "@/components/molecules/menu-mobile/MobileMenuContent";
import MobileMenuTrigger from "@/components/molecules/menu-mobile/MobileMenuTrigger";
import SearchBoxMobile from "@/components/molecules/search-box/SearchBoxMobile";
import SearchTrigger from "@/components/molecules/search-box/SearchTrigger";

export default {
  components: {
    MastheadLogo,
    MobileMenuContent,
    MobileMenuTrigger,
    SearchBoxMobile,
    SearchTrigger,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    logo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMenuExpanded: false,
      isSearchExpanded: false,
    };
  },
  watch: {
    isMenuExpanded(isExpanded) {
      return isExpanded ? this.preventBodyScroll() : this.allowBodyScroll();
    },
  },
  methods: {
    toggleMenu() {
      this.isSearchExpanded = false;
      this.isMenuExpanded = !this.isMenuExpanded;
    },
    toggleSearch() {
      this.isMenuExpanded = false;
      this.isSearchExpanded = !this.isSearchExpanded;
    },
    preventBodyScroll() {
      document.body.style.overflow = "hidden";
    },
    allowBodyScroll() {
      document.body.style.overflow = "auto";
    },
  },
};
</script>
