<template>
  <nav aria-label="Primary navigation" class="m-menu--mobile">
    <ul class="nav nav__level-1">
      <li v-for="(item, index) in items" :key="item.id" class="nav-item">
        <span class="nav-inner">
          <a :href="item.link" :target="item.target">{{ item.title }}</a>
          <button
            v-if="item.children.length"
            type="button"
            class="nav-toggle"
            @click="toggleExpanded(index)"
          >
            {{ isExpanded(index) ? "Close" : "Expand" }}
            <svg
              :class="expanderState(index)"
              viewBox="0 0 32 32"
              height="18"
              width="18"
              aria-hidden="true"
            >
              <path
                fill="currentColor"
                d="M16.003 18.626l7.081-7.081L25 13.46l-8.997 8.998-9.003-9 1.917-1.916z"
              />
            </svg>
          </button>
        </span>
        <ul v-if="isExpanded(index)" class="nav nav__level-2">
          <li v-for="child in item.children" :key="child.id" class="nav-item">
            <a :href="child.link" :target="child.target">{{ child.title }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      expandedItems: this.items.map(() => false),
    };
  },
  methods: {
    expanderState(index) {
      return this.isExpanded(index) ? "active" : "";
    },
    toggleExpanded(index) {
      this.$set(this.expandedItems, index, !this.expandedItems[index]);
    },
    isExpanded(index) {
      return this.expandedItems[index];
    },
  },
};
</script>
