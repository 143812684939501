<template>
  <form action="/" class="wrapper">
    <div class="p-top p-bottom g g--no-gutters align-v-center">
      <div class="m-search-box g__col-12 g g--no-gutters align-v-center">
        <label :for="id" class="u-screen-reader-only">Enter search keywords</label>
        <input
          :id="id"
          class="input"
          type="text"
          name="s"
          value=""
          placeholder="Search this site…"
        />
        <button type="submit" class="m-search-box--btn m-search-box--btn-search">
          <svg height="24" width="24" viewBox="0 0 24 24">
            <use xlink:href="#icon-search" />
          </svg>
          <span class="u-screen-reader-only">Search</span>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  computed: {
    id() {
      return `search-${Math.random(9999)}`;
    },
  },
};
</script>
