import Vue from "vue";
import Masthead from "./Masthead";

export const masthead = (configs) =>
  configs.map(
    ({ el, items, logo }) =>
      new Vue({
        el,
        name: "MastheadRoot",
        render(h) {
          return h(Masthead, {
            props: {
              items,
              logo,
            },
          });
        },
      })
  );
