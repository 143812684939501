// import { myModule } from "@/components/path/to/module/index";

// This wouls normally be a named export in a file as per commented path above,
// here just to demo the pattern
import { masthead } from "@/components/molecules/masthead";
import { slider } from "@/components/molecules/slider";

export default {
  masthead,
  slider,
};
