<template>
  <nav aria-label="Primary navigation" class="m-menu">
    <ul class="nav nav__level-1 g align-end align-v-center">
      <li
        v-for="item in items"
        :key="item.id"
        :class="{
          'nav-item--has-children': item.children,
          'nav-item--active': item.currentTopLevel,
        }"
        class="g__col nav-item"
      >
        <a :href="item.link">{{ item.title }}</a>
        <button
          v-if="item.children.length"
          :aria-label="expanderTitle(item)"
          :class="expanderState(item)"
          @click="$emit('toggleitem', { item })"
        >
          <svg viewBox="0 0 32 32" height="18" width="18" aria-hidden="true">
            <path
              fill="currentColor"
              d="M16.003 18.626l7.081-7.081L25 13.46l-8.997 8.998-9.003-9 1.917-1.916z"
            />
          </svg>
        </button>
      </li>
      <li class="nav-item g align-v-center">
        <search-trigger @toggle="$emit('togglesearch')" />
      </li>
    </ul>
  </nav>
</template>
<script>
import SearchTrigger from "@/components/molecules/search-box/SearchTrigger";

export default {
  components: {
    SearchTrigger,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    expandedItem: {
      type: [Object, null],
      default: () => null,
    },
  },

  methods: {
    expanderState(item) {
      return this.isExpanded(item) ? "nav-toggle active" : "nav-toggle";
    },
    expanderTitle(item) {
      return this.isExpanded(item) ? "Expand section navigation" : "Hide section navigation";
    },
    isExpanded(item) {
      return item === this.expandedItem;
    },
  },
};
</script>
