function Toggle($trigger) {
  const selector = $trigger.getAttribute("data-toggle");
  const target = document.querySelector(selector);

  this.$trigger = {
    el: $trigger,
    defaultText: $trigger.innerHTML,
    expandedText: $trigger.getAttribute("data-expanded-text") || $trigger.innerHTML,
    activeClass: $trigger.getAttribute("data-target-active-class") || this.opts.triggerActiveClass,
    ariaLabel: $trigger.getAttribute("aria-label"),
  };

  this.$target = {
    selector,
    el: target,
    activeClass: $trigger.getAttribute("data-target-active-class") || this.opts.targetActiveClass,
    isAriaHidden: target.hasAttribute("aria-hidden"),
  };

  this.init();
}

Toggle.prototype = {
  constructor: Toggle,
  opts: {
    triggerActiveClass: "is--active",
    targetActiveClass: "is--js-hidden",
  },
  init() {
    this.bind();
  },
  bind() {
    this.$trigger.el.addEventListener("click", (e) => {
      e.preventDefault();
      this.toggle();
    });
  },
  toggle() {
    this.$trigger.el.classList.toggle(this.$trigger.activeClass);
    this.$target.el.classList.toggle(this.$target.activeClass);
    this.toggleTriggerText();
  },
  toggleTriggerText() {
    if (this.usesExpandedText()) {
      this.$trigger.el.innerHTML = this.$trigger.defaultText;
      if (this.$trigger.ariaLabel) {
        this.$trigger.el.setAttribute("aria-label", this.$trigger.ariaLabel);
      }
    } else {
      this.$trigger.el.innerHTML = this.$trigger.expandedText;
      if (this.$trigger.ariaLabel) {
        this.$trigger.el.setAttribute("aria-label", this.$trigger.expandedText);
      }
    }
  },
  usesExpandedText() {
    return this.$trigger.el.innerHTML === this.$trigger.expandedText;
  },
};

const initToggles = (global) => {
  /* eslint-disable no-param-reassign */
  global.widgets = global.widgets || {};
  global.widgets.toggles = [...document.querySelectorAll("[data-toggle]")].map(
    (t) => new Toggle(t)
  );
};

window.SHU = window.SHU || {};

window.addEventListener("DOMContentLoaded", () => initToggles(window.SHU));
