<template>
  <div class="logo" itemscope itemtype="http://schema.org/Organization">
    <a
      class="logo-link"
      itemprop="url"
      :href="logo.url"
      title="Return to the Sheffield Hallam University home page"
    >
      <span class="u-screen-reader-only">Sheffield Hallam University</span>
    </a>
    <img class="logo-image" :src="logo.src" alt="Sheffield Hallam University logo image" />
  </div>
</template>
<script>
export default {
  props: {
    logo: {
      type: Object,
      required: true,
    },
  },
};
</script>
