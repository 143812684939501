<template>
  <nav :aria-label="'Section navigation for ' + parent.title" class="m-menu p-top nav__level-2">
    <div class="wrapper">
      <ul class="nav g align-end p-top p-bottom">
        <li
          v-for="item in items"
          :key="item.id"
          :class="{
            'nav-item--active': item.current || item.current_item_ancestor,
          }"
          class="g__col nav-item"
        >
          <a :href="item.link">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    parent: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
