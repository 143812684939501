<template>
  <button v-if="isExpanded" :class="state" :aria-label="triggerLabel" @click.prevent="toggle">
    <svg height="24" width="24" viewBox="0 0 24 24" alt="close icon">
      <use xlink:href="#icon-close" />
    </svg>
  </button>
  <button v-else :class="state" :aria-label="triggerLabel" @click.prevent="toggle">
    <svg height="24" width="24" viewBox="0 0 24 24" alt="hamburger icon">
      <use xlink:href="#icon-hamburger" />
    </svg>
  </button>
</template>
<script>
export default {
  props: {
    isExpanded: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    triggerLabel() {
      return `${this.isExpanded ? "Hide" : "Show"} menu`;
    },
    state() {
      return `${this.isExpanded ? "btn btn--is-active" : "btn"}`;
    },
  },
  methods: {
    toggle() {
      this.$emit("toggle");
    },
  },
};
</script>
