<template>
  <component :is="menuComponent" :items="items" :logo="logo" />
</template>
<script>
import { breakpointMixin } from "@/mixins/breakpointMixin";
import MobileMasthead from "./MobileMasthead";
import DesktopMasthead from "./DesktopMasthead";

export default {
  name: "Menu",
  components: {
    MobileMasthead,
    DesktopMasthead,
  },
  mixins: [breakpointMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    logo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    menuComponent() {
      return this.isMobile ? "MobileMasthead" : "DesktopMasthead";
    },
  },
};
</script>
